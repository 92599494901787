import React from "react"
import { Container, Row } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import ProductHero from "../components/ProductHero/ProductHero"
import SendgridAddContact from "../components/SendgridAddContact/SendgridAddContact"
import QandAComponent from "../components/QandAComponent/QandAComponent"

import { clickEvent } from "../utils/events"

const SignupFormHero = (
  <SendgridAddContact
    listName="ieee20305"
    title="Sound interesting?"
    description="Sign up to be the first to hear 2030.5 updates (that's all we'll use your email for)."
  />
)

const Ieee20305Page = props => (
  <Layout>
    <SEO title="2030.5 Solution" />
    <ProductHero
      title="Get up and running with IEEE 2030.5"
      paragraph1="We are adding the IEEE 2030.5 protocol to our products, allowing companies to incorporate the protocol quickly and cost effectively into their platforms and products"
      image={props.data.heroImage.image.fluid}
      signup={SignupFormHero}
    />
    <Container style={{ maxWidth: "600px" }}>
      <div style={{ margin: "50px 0 50px 0" }}>
        <h2 className="section-header">IEEE 2030.5</h2>
        <div style={{ maxWidth: "700px", margin: "auto" }}>
          <p>
            <OutboundLink
              href="https://standards.ieee.org/standard/2030_5-2018.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              IEEE 2030.5
            </OutboundLink>{" "}
            is a standard, XML-based application protocol used primarily to
            manage Distributed Energy Resources (DERs) such as energy storage,
            rooftop solar panels, Vehicle to Grid EV charging, and more.
          </p>
          <p>
            Use of IEEE 2030.5 is increasingly a requirement for DER
            interconnections (for example{" "}
            <OutboundLink
              href="https://www.pge.com/includes/docs/pdfs/shared/customerservice/nonpgeutility/electrictransmission/handbook/rule21-implementation-guide.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              California Rule 21
            </OutboundLink>
            ), to ensure future DERs will be available to provide grid services
            capably, scalably and interoperably
          </p>
          <p>
            Unlike a backbone protocol such as OpenADR, IEEE 2030.5 specializes
            in device-specific functionality. Therefore, we expect to see IEEE
            2030.5 deployed in multiple formats - from a client solution on
            device to a server at the utility.
          </p>
        </div>
      </div>
      <div style={{ margin: "50px 0 50px 0" }}>
        <h2 className="section-header">Buy instead of build</h2>
        <div style={{ maxWidth: "700px", margin: "auto" }}>
          <p>
            For most companies, implementing protocols is table stakes, not a
            competitive advantage. We offer the option to buy a software
            solution and get up and running with protocols faster, with less
            risk and more cost effectively than building in house.
          </p>
          <p>
            Through our products{" "}
            <Link
              to="/plaid"
              onClick={() => clickEvent("20305-page", "20305 to Plaid")}
            >
              Plaid
            </Link>{" "}
            and{" "}
            <Link
              to="/canvas"
              onClick={() => clickEvent("20305-page", "20305 to Canvas")}
            >
              Canvas
            </Link>
            , we have supported our customers through dozens of OpenADR
            implementations around the world, offering an affordable option to
            buy a full implementation of the protocol rather than learning and
            implementing it themselves.
          </p>
          <p>
            Now, we are incorporating IEEE 2030.5 into our Plaid and Canvas
            products in the same model we have offered for OpenADR, so our
            customers may quickly start using OpenADR, IEEE 2030.5, or both.
          </p>
        </div>
      </div>
      <Row style={{ marginBottom: "100px" }}>
        <SendgridAddContact
          listName="ieee20305"
          title="Sound interesting?"
          description="Sign up to be the first to hear updates on our IEEE 2030.5 implementation (that's all we'll use your email for)."
        />
      </Row>
    </Container>
    <div style={{ backgroundColor: "#f6f8fa", padding: "5% 0" }}>
      <div className="divider-image">
        <Img fixed={props.data.icon.image.fixed} alt="" />
      </div>
      <Container style={{ maxWidth: "1000px" }}>
        <Row>
          <QandAComponent
            question="How will it work?"
            answer="It will be implemented as a stand-alone microservice than runs in most Linux environments, or in a docker container. It will implement the protocol 'under the hood' and expose the parts you need to interact with through a REST API. Your team will receive the source code, and you build and run it in your environment as though you had built it yourself."
          />
          <QandAComponent
            question="What is the pricing model?"
            answer="We will maintain the licensed software model from Plaid and Canvas. The OpenADR and IEEE 2030.5 implementations will be available as modules, priced individually."
          />
          <QandAComponent
            question="Can I implement it on my device / my cloud / etc?"
            answer="You can implement it on any system that runs a capable Linux environment."
          />
          <QandAComponent
            question="When will it be ready?"
            answer="We are targeting an initial release in 2020."
          />
          <QandAComponent
            question="What functionality will it have?"
            answer="Since California Rule 21 and CSIP is currently the most urgent need for 2030.5, we will start by fulfilling the CSIP profile (a subset of the full IEEE 2030.5 protocol). Then we will add additional capabilities from there."
          />
          <QandAComponent
            question="Can my product I become CSIP certified with this solution?"
            answer="Yes, CSIP certification is one of the requirements. We aren't yet sure exactly what the process will be to get our customers certified, but we plan to make it as seamless as possible."
          />
        </Row>
      </Container>
    </div>
  </Layout>
)

export default Ieee20305Page

export const ieee20305query = graphql`
  query {
    icon: contentfulWebsiteImages(imageId: { eq: "gridfabricMiniIcon" }) {
      image {
        fixed(width: 30) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
    }
    heroImage: contentfulWebsiteImages(imageId: { eq: "canvasHero" }) {
      ...standardImage
    }
  }
`

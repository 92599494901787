import React from "react"
import { Col } from "react-bootstrap"

const QandAComponent = ({question, answer}) => (
  <Col xs={12} md={6} style={{ padding: "20px" }}>
    <h4>{question}</h4>
    <p className="text">{answer}</p>
  </Col>
)

export default QandAComponent

import React, { useState } from "react"
import axios from "axios"
import { Form, Row, Container, Col, Button } from "react-bootstrap"
import { Link } from "gatsby"
import { clickEvent, conversionEvent, getLocation } from "../../utils/events"

import styles from "./SendgridAddContact.module.css"

const SendgridAddContact = ({ listName, title, description }) => {
  const [formError, updateFormError] = useState("")
  const [formLoading, updateFormLoading] = useState(false)
  const [showFormSuccess, updateFormSuccess] = useState(false)

  const handleSubmit = event => {
    //check honeypot
    if (event.target[0].value) return null

    clickEvent("Add To Sendgrid List", listName, getLocation())

    updateFormError("")
    updateFormLoading(true)

    const formData = {
      email: event.target[1].value,
      listName: listName,
    }

    event.preventDefault()
    event.stopPropagation()

    axios
      .post("/.netlify/functions/sendgridAddToList", formData)
      .then(() => {
        updateFormSuccess(true)
        conversionEvent("AW-690244398/bPpzCN3_qNABEK6WkckC")
      })
      .catch(() => {
        updateFormError("Sorry, there was an error")
      })
      .finally(() => {
        updateFormLoading(false)
      })
  }

  return showFormSuccess ? (
    <Container
      className={styles.containerStyling}
      style={{ padding: "50px", textAlign: "center" }}
    >
      <p>Your email has been added to the list. Thank you!</p>
      <Button
        className="btn-gridfabric-outline"
        onClick={() => updateFormSuccess(false)}
      >
        Back
      </Button>
    </Container>
  ) : (
    <Container className={styles.containerStyling}>
      <h6 className={styles.sectionHeader}>
        {title || "Interested in learning more?"}
      </h6>
      <p>
        {description ||
          "Sign up for our quarterly(ish) newsletter with industry & protocol news, commentary and product updates."}
      </p>
      <Form onSubmit={handleSubmit} name="newsletter_signup">
        <input type="hidden" name="bot-field" />

        <Form.Group className={styles.labels} as={Row}>
          <Col>
            <Form.Control
              type="email"
              id="email"
              placeholder="Enter email"
              name="email"
              required
            />
          </Col>
          <Button
            className="btn-gridfabric"
            type="submit"
            disabled={formLoading}
          >
            Sign up
          </Button>
        </Form.Group>
      </Form>{" "}
      <p>{formError && <p className={styles.errorText}>{formError}</p>}</p>
      <p>
        Or if you'd like to discuss,{" "}
        <Link
          to="/about#contact"
          onClick={() =>
            clickEvent("Contact from Sendgrid Module", listName, getLocation())
          }
        >
          contact us
        </Link>
      </p>
    </Container>
  )
}

export default SendgridAddContact

import React from 'react'
import {Row, Container, Col, Button} from 'react-bootstrap'
import Img from 'gatsby-image'

import styles from './ProductHero.module.css'

const Hero = ({
  title,
  paragraph1,
  paragraph2,
  image,
  cta,
  ctaClick,
  demo,
  demoLink,
  signup,
  openadrBadge,
}) => (
  <div className={styles.background}>
    <Container>
      <Row className={styles.row}>
        <Col
          md={{ span: 5, order: 0 }}
          lg={{ span: 6, order: 0 }}
          xs={{ span: 12, order: 1 }}
          className={styles.textSection}
        >
          <div className={styles.titleSection}>
            <div className={styles.titleGroup}>
              <h1 className={styles.title}>{title}</h1>
              <div className={styles.badgeSection}>
                <Img fixed={openadrBadge} alt="OpenADR 2.0b certified" />
              </div>
            </div>
            <p className={styles.subtitle}>{paragraph1}</p>
            <p className={styles.subtitle}>{paragraph2}</p>
          </div>
          <Row>
            {demo && demoLink && (
              <div className={styles.ctaSection}>
                <div className={styles.ctaButton}>
                  <a href={demoLink} target="_blank" rel="noreferrer noopener">
                    <Button className="btn-gridfabric-secondary btn-lg">
                      {demo}
                    </Button>
                  </a>
                </div>
              </div>
            )}
            {cta && (
              <div className={styles.ctaSection}>
                <div className={styles.ctaButton}>
                  <Button
                    className="btn-gridfabric-outline btn-lg"
                    onClick={ctaClick}
                  >
                    {cta}
                  </Button>
                </div>
              </div>
            )}
          </Row>
          {signup && <div>{signup}</div>}
        </Col>
        <Col md={7} lg={6} className={styles.imageContainer}>
          <Img fluid={image} alt="Hero" className={styles.image} />
        </Col>
      </Row>
    </Container>
  </div>
)

export default Hero